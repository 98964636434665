<template>
  <div v-if="notificationDevices" class="px-4">
    <v-slide-group
      class="p-0 m-0 pt-3"
      :style="$root.mobile ? 'min-width: 96vw;' : ''"
      :show-arrows="false"
    >
      <v-slide-item>
        <div
          class="userprofile_box white--text listitem p-4 py-4 primary lighten-1 pushxl"
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
        >
          <div class="inner pushxl">
            <unicon
              name="laptop-cloud"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon
            ><span>{{ $t("PROFILE.notification.web") }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5">{{
            webtotal
          }}</span>
        </div>
      </v-slide-item>
      <v-slide-item>
        <div
          class="userprofile_box white--text listitem p-4 py-4 primary lighten-1 pushxl"
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
        >
          <div class="inner pushxl">
            <unicon
              name="apple"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon
            ><span>{{ $t("PROFILE.notification.ios") }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5">{{
            iostotal
          }}</span>
        </div>
      </v-slide-item>
      <v-slide-item>
        <div
          class="userprofile_box white--text listitem p-4 py-4 primary lighten-1 pushxl"
          :style="
            $root.mobile ? 'width: 55vw;min-width: 55vw;' : 'min-width:270px'
          "
        >
          <div class="inner pushxl">
            <unicon
              name="android"
              fill="#FFFFFF"
              height="20"
              class="pushx"
            ></unicon
            ><span>{{ $t("PROFILE.notification.android") }}</span>
          </div>
          <v-spacer /><span class="font-weight-black text-h5">{{
            androidtotal
          }}</span>
        </div>
      </v-slide-item>
    </v-slide-group>

    <v-list>
      <template v-for="device in notificationDevices">
        <v-list-item class="listitem mb-2" two-line :key="device.id">
          <v-list-item-avatar color="rgba(165, 203, 239, 0.20)">
            <unicon
              style="margin-right:auto;margin-left:auto"
              v-if="device.platform == 'web'"
              name="laptop"
              fill="#428aeb"
              height="20"
            />
            <unicon
              v-else
              style="margin-right:auto;margin-left:auto"
              name="mobile-android"
              fill="#428aeb"
              height="20"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="device.platform == 'web'"
                >{{ device.device_info.operatingSystem }}
                {{ device.device_info.model }} OS
                {{ device.device_info.osVersion }}</span
              >
              <span v-else-if="device.device_info"
                >{{ device.device_info.manufacturer }}
                {{ device.device_info.model }} OS
                {{ device.device_info.osVersion }}</span
              >
              <span v-else>Send to {{ device.platform }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t("PROFILE.notification.edited") }} {{ device.created_on }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="doedit"
              small
              fab
              elevation="0"
              color="rgba(239, 165, 179, 0.25)"
            >
              <unicon
                style="margin-right: auto;margin-left: auto;"
                name="trash-alt"
                fill="#f64e60"
                height="20"
              ></unicon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
  <v-row align="center" justify="center" style="height:10vh" v-else>
    <v-progress-circular color="primary" indeterminate />
  </v-row>
</template>
<script>
export default {
  name: "NotificationsProfile",
  components: {},
  props: ["uid"],
  data() {
    return {
      doedit: true,
      notificationDevices: null
    };
  },
  beforeMount() {},
  activated() {
    if (this.notificationDevices == null) {
      this.loadNotifications();
    }
  },
  deactivated() {
    this.notificationDevices = null;
  },
  mounted() {},
  methods: {
    async loadNotifications() {
      await this.$notify.getDevicesByUser(this.uid).then(data => {
        this.notificationDevices = data.data.data;
      });
    }
  },
  computed: {
    webtotal() {
      let total = 0;
      if (this.notificationDevices) {
        this.notificationDevices.forEach(device => {
          if (device.platform == "web") total += 1;
        });
      }
      return total;
    },
    iostotal() {
      let total = 0;
      if (this.notificationDevices) {
        this.notificationDevices.forEach(device => {
          if (
            device.platform != "web" &&
            device.device_info &&
            device.device_info.platform == "ios"
          )
            total += 1;
        });
      }
      return total;
    },
    androidtotal() {
      let total = 0;
      if (this.notificationDevices) {
        this.notificationDevices.forEach(device => {
          if (
            device.platform != "web" &&
            device.device_info &&
            device.device_info.platform == "android"
          )
            total += 1;
        });
      }
      return total;
    }
  }
};
</script>
